import React, { Fragment, useState } from 'react'
import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  Radio,
  FormControlLabel,
  Button,
  Checkbox
} from '@material-ui/core'
import { parseJson } from '../../../../components/formatters/parseJson'
import Modal from '../modal'
import PackageInfo from '../packageInfo'
import UserInfo from '../userInfo'
import Activity from '../activity'
import { CreditCard } from '../../../creditCard'
import { TokenexASCIIDisplay } from '../../../tokenexASCIIDisplay'
import { TokenexSSNDisplay } from '../../../tokenexSSNDisplay'

const securityQuestionDictionary = {
  hero: 'Who is your favorite childhood hero?',
  restaurant: 'What is your favorite restaurant?',
  singer: 'Who is your favorite singer?',
  actor: 'Who is your favorite actor?'
}

const CreditCheckInfo = ({
  record,
  classes,
  creditCheck,
  dobToken,
  ssnToken,
  securityAnswerToken,
  passcodeToken
}) => {
  const [openSsnModal, setOpenSsnModal] = useState(false)
  const handleOpenSsnModal = () => setOpenSsnModal(true)
  const handleCloseSsnModal = () => setOpenSsnModal(false)
  const [openDobModal, setOpenDobModal] = useState(false)
  const handleOpenDobModal = () => setOpenDobModal(true)
  const handleCloseDobModal = () => setOpenDobModal(false)
  const [openSecurityAnswerModal, setOpenSecurityAnswerModal] = useState(false)
  const handleOpenSecurityAnswerModal = () => setOpenSecurityAnswerModal(true)
  const handleCloseSecurityAnswerModal = () => setOpenSecurityAnswerModal(false)
  const [openPasscodeModal, setOpenPasscodeModal] = useState(false)
  const handleOpenPasscodeModal = () => setOpenPasscodeModal(true)
  const handleClosePasscodeModal = () => setOpenPasscodeModal(false)

  return (
    <Fragment>
      <CardHeader title="Credit Check" />
      <div>
        <FormControlLabel
          control={
            <Radio checked={true} classes={{ disabled: classes.disabled }} />
          }
          label="I authorize a credit check via my Social Security Number (SSN)"
          labelPlacement="end"
          disabled
          classes={{ label: classes.label }}
        />
      </div>
      <div style={{ marginTop: 20 }}>
        <h3>SSN</h3>
      </div>
      <div style={{ marginTop: 20 }}>
        <Button variant="contained" onClick={handleOpenSsnModal}>
          Show Full SSN Info
        </Button>
        <Modal
          open={openSsnModal}
          onClose={handleCloseSsnModal}
          title="Full SSN Info"
        >
          <div>
            {ssnToken ? (
              <TokenexSSNDisplay token={ssnToken} />
            ) : (
              <div>{creditCheck?.ssn}</div>
            )}
          </div>
        </Modal>
      </div>
      <h3>Date of Birth</h3>
      <div style={{ marginTop: 20 }}>
        <Button variant="contained" onClick={handleOpenDobModal}>
          Show Date of Birth
        </Button>
        <Modal
          open={openDobModal}
          onClose={handleCloseDobModal}
          title="Date of Birth"
        >
          <div>
            {dobToken ? (
              <TokenexASCIIDisplay token={dobToken} />
            ) : (
              <div>{creditCheck?.dateOfBirth}</div>
            )}
          </div>
        </Modal>
      </div>
      <h3>Security Code</h3>
      <div style={{ marginTop: 20 }}>
        <Button variant="contained" onClick={handleOpenPasscodeModal}>
          Show Security Code
        </Button>
        <Modal
          open={openPasscodeModal}
          onClose={handleClosePasscodeModal}
          title="Passcode"
        >
          <div>
            {passcodeToken ? (
              <TokenexASCIIDisplay token={passcodeToken} />
            ) : (
              <div>{creditCheck?.passcode}</div>
            )}
          </div>
        </Modal>
      </div>
      <h3>Security Question</h3>
      <div>
        {
          securityQuestionDictionary[
            creditCheck?.securityQuestion ||
              record?.credit_check?.security_questions?.security_question
          ]
        }
      </div>
      <h3>Security Answer</h3>
      <div style={{ marginTop: 20 }}>
        <Button variant="contained" onClick={handleOpenSecurityAnswerModal}>
          Show Security Answer
        </Button>
        <Modal
          open={openSecurityAnswerModal}
          onClose={handleCloseSecurityAnswerModal}
          title="Security Answer"
        >
          <div>
            {securityAnswerToken ? (
              <TokenexASCIIDisplay token={securityAnswerToken} />
            ) : (
              <div>{creditCheck?.securityAnswer}</div>
            )}
          </div>
        </Modal>
      </div>
      <h4>Verification - Credit Result / Credit Option</h4>
      <div>
        <em>
          Contact customer if a credit management fee is required or a credit
          check fails.
        </em>
      </div>
    </Fragment>
  )
}

const AdditionalOptions = ({ classes, record }) => {
  const installationType = record.offer?.options?.installation_options?.type
  return (
    <Fragment>
      <h3>May AT&T use your contact numbers to reach you by phone or text?</h3>
      <div>
        <FormControlLabel
          control={
            <Radio
              checked={record.opt_ins?.sms_opt_in}
              classes={{ disabled: classes.disabled }}
            />
          }
          label="Yes, I consent"
          labelPlacement="end"
          disabled
          classes={{ label: classes.label }}
        />
      </div>
      <div>
        <FormControlLabel
          control={
            <Radio
              checked={!record.opt_ins?.sms_opt_in}
              classes={{ disabled: classes.disabled }}
            />
          }
          label="No, I do not consent"
          labelPlacement="end"
          disabled
          classes={{ label: classes.label }}
        />
      </div>
      <h3>ConnecTech Support Plus Add-on</h3>
      <div>
        <FormControlLabel
          control={
            <Radio checked={true} classes={{ disabled: classes.disabled }} />
          }
          label="No"
          labelPlacement="end"
          disabled
          classes={{ label: classes.label }}
        />
      </div>
      <h3>Choose type of installation</h3>
      <div>
        <FormControlLabel
          control={
            <Radio
              checked={installationType === 'self'}
              classes={{ disabled: classes.disabled }}
            />
          }
          label="Self Install (Free)"
          labelPlacement="end"
          disabled
          classes={{ label: classes.label }}
        />
      </div>
      <div>
        <FormControlLabel
          control={
            <Radio
              checked={installationType === 'professional'}
              classes={{ disabled: classes.disabled }}
            />
          }
          label="Professional Install (Free)"
          labelPlacement="end"
          disabled
          classes={{ label: classes.label }}
        />
      </div>
      <div>
        <h3>
          {installationType === 'professional'
            ? 'Installation Date'
            : 'Activation Date'}
        </h3>
        <InstallationScheduling classes={classes} record={record} />
      </div>
      <h3>
        Would you like to split your one-time fees into three equal monthly
        installments?
      </h3>
      <div>
        <FormControlLabel
          control={
            <Radio checked={true} classes={{ disabled: classes.disabled }} />
          }
          label="No, bill me for all one-time charges on my next monthly bill"
          labelPlacement="end"
          disabled
          classes={{ label: classes.label }}
        />
      </div>
      <h3>
        In the future, AT&T may use your email address to ask you a few
        questions about your experience.
      </h3>
      <div>
        <FormControlLabel
          control={
            <Radio checked={true} classes={{ disabled: classes.disabled }} />
          }
          label="No, I would like to opt out of the survey."
          labelPlacement="end"
          disabled
          classes={{ label: classes.label }}
        />
      </div>
    </Fragment>
  )
}

const PromotionsOptions = ({ classes, creditCheck, record }) => {
  const creditCheckMethod =
    creditCheck?.creditCheckMethod?.toLowerCase() ||
    record?.credit_check?.credit_check_method?.toLowerCase()

  return (
    <Fragment>
      <h3>
        $5.00 off per month when signing up for Auto Bill Pay and Paperless
        Billing
      </h3>
      <div>
        <FormControlLabel
          control={
            <Radio
              checked={creditCheckMethod === 'autopay'}
              classes={{ disabled: classes.disabled }}
            />
          }
          label="I would like to enroll in Auto Pay Bills and Paperless billing and receive $5 off Internet per month"
          labelPlacement="end"
          disabled
          classes={{ label: classes.label }}
        />
      </div>
      <div>
        <FormControlLabel
          control={
            <Radio
              checked={creditCheckMethod === 'manual'}
              classes={{ disabled: classes.disabled }}
            />
          }
          label="I would like to pay manually"
          labelPlacement="end"
          disabled
          classes={{ label: classes.label }}
        />
      </div>
      <h3>
        If there is a Rewards Card option, select YES on the customer&apos;s
        behalf.
      </h3>
      <div>
        <FormControlLabel
          control={
            <Radio checked={true} classes={{ disabled: classes.disabled }} />
          }
          label="Yes"
          labelPlacement="end"
          disabled
          classes={{ label: classes.label }}
        />
      </div>
      <h3>
        If there is an unlimited data upgrade option, select NO on the
        customer&apos;s behalf.
      </h3>
      <div>
        <FormControlLabel
          control={
            <Radio checked={true} classes={{ disabled: classes.disabled }} />
          }
          label="No"
          labelPlacement="end"
          disabled
          classes={{ label: classes.label }}
        />
      </div>
      <h3>
        If there is an upgrade to a higher speed plan available, select NO on
        the customer&apos;s behalf.
      </h3>
      <div>
        <FormControlLabel
          control={
            <Radio checked={true} classes={{ disabled: classes.disabled }} />
          }
          label="No"
          labelPlacement="end"
          disabled
          classes={{ label: classes.label }}
        />
      </div>
      <h3>
        If there is a pro-rated monthly discount, select YES on the
        customer&apos;s behalf.
      </h3>
      <div>
        <FormControlLabel
          control={
            <Radio checked={true} classes={{ disabled: classes.disabled }} />
          }
          label="Yes"
          labelPlacement="end"
          disabled
          classes={{ label: classes.label }}
        />
      </div>
      <h3>If HBO is free, select YES on the customer&apos;s behalf.</h3>
      <div>
        <FormControlLabel
          control={
            <Radio checked={true} classes={{ disabled: classes.disabled }} />
          }
          label="Yes"
          labelPlacement="end"
          disabled
          classes={{ label: classes.label }}
        />
      </div>
    </Fragment>
  )
}

const ShippingInformation = ({
  classes,
  record: {
    move: { to_address = {} } = {},
    contact_information: { new_shipping_address = {}, shipping_address }
  } = {}
}) => (
  <Fragment>
    {!shipping_address ? (
      <>
        <FormControlLabel
          control={
            <Checkbox checked={true} classes={{ disabled: classes.disabled }} />
          }
          label="Same as Service Address"
          labelPlacement="end"
          classes={{ label: classes.label }}
          disabled
        />
        <div>Street: {to_address.street}</div>
        <div>Apartment: {to_address.apt}</div>
        <div>City: {to_address.city}</div>
        <div>State: {to_address.state}</div>
        <div>Zip: {to_address.zip}</div>
      </>
    ) : (
      <>
        {shipping_address !== 'Somewhere else' ? (
          <div>{shipping_address}</div>
        ) : (
          <>
            <div>Street: {new_shipping_address.street}</div>
            <div>Apartment: {new_shipping_address.unit}</div>
            <div>City: {new_shipping_address.city}</div>
            <div>State: {new_shipping_address.state}</div>
            <div>Zip: {new_shipping_address.postal_code}</div>
          </>
        )}
      </>
    )}
  </Fragment>
)

const BillingInfo = ({ record }) => {
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const {
    billing_address: billingAddress = {},
    expiration_date: expirationDate,
    first_name: firstName,
    last_name: lastName,
    pci = {}
  } = record?.payment?.credit_card || {}
  const creditCardProps = {
    ...pci,
    expirationDate,
    firstName,
    lastName
  }
  return (
    <Fragment>
      <div>
        <h3>Credit Card Info</h3>
        <div>
          <Button variant="contained" onClick={handleOpen}>
            Show Credit Card Info
          </Button>
          <Modal open={open} onClose={handleClose} title="Credit Card Info">
            <CreditCard
              {...creditCardProps}
              allowTokenexDetokenizer={true}
              allowTokenDisplay={true}
            />
          </Modal>
        </div>
      </div>
      <div>
        <h3>Billing Address</h3>
        <div>
          <b>Street:</b> {billingAddress?.street}
        </div>
        <div>
          <b>Apartment:</b> {billingAddress?.unit}
        </div>
        <div>
          <b>City:</b> {billingAddress?.city}
        </div>
        <div>
          <b>State:</b> {billingAddress?.state}
        </div>
        <div>
          <b>Zip Code:</b>{' '}
          {billingAddress?.postalCode || billingAddress?.postal_code}
        </div>
      </div>
    </Fragment>
  )
}

const InstallationDate = ({ classes, dateNumber, installation }) => {
  // each date is its own property, including windows, so have to access dynamically
  const dateNumberStr = `date${dateNumber}`
  const date = installation[dateNumberStr]
  const installationWindows =
    installation[`${dateNumberStr}_installation_window`] || []
  return (
    <Fragment>
      <h4>Date {dateNumber}</h4>
      <p>{new Date(date).toLocaleDateString()}</p>
      <ul>
        {installationWindows
          .sort((a, b) => {
            return a > b ? -1 : 1
          })
          .map((window, i) => (
            <li key={i}>{window}</li>
          ))}
      </ul>
    </Fragment>
  )
}

const InstallationScheduling = ({ classes, record }) => {
  return (
    <Fragment>
      <p>
        (Select the earliest date and time available within AT&T installation
        schedule)
      </p>
      <h4>Customer&apos;s preferred date(s) & time(s)</h4>

      <InstallationDate
        classes={classes}
        dateNumber={1}
        installation={record.installation}
      />
      {Boolean(record.installation?.date2_installation_window?.length) && (
        <>
          <InstallationDate
            classes={classes}
            dateNumber={2}
            installation={record.installation}
          />
          <InstallationDate
            classes={classes}
            dateNumber={3}
            installation={record.installation}
          />
        </>
      )}
    </Fragment>
  )
}

export const OrderView = ({ record, classes }) => {
  const ssnToken = parseJson(record?.credit_check?.ssn?.token)?.token
  const dobToken = parseJson(record?.credit_check?.date_of_birth?.token)?.token
  const securityAnswerToken = parseJson(
    record?.credit_check?.security_questions?.security_answer?.token
  )?.token
  const passcodeToken = parseJson(record?.credit_check?.passcode?.token)?.token
  const installationType = record.offer?.options?.installation_options?.type
  return (
    <Fragment>
      <Grid item xs={6}>
        <UserInfo record={record} />
      </Grid>
      <Grid item xs={6}>
        <PackageInfo record={record} />
      </Grid>
      <Grid item xs={12}>
        <Card style={{ marginTop: 20 }}>
          <CardHeader />
          <CardContent>
            <CreditCheckInfo
              record={record}
              classes={classes}
              ssnToken={ssnToken}
              dobToken={dobToken}
              securityAnswerToken={securityAnswerToken}
              passcodeToken={passcodeToken}
            />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card style={{ marginTop: 20 }}>
          <CardHeader title="Equipment Options" />
          <CardContent>
            <FormControlLabel
              control={
                <Checkbox
                  checked={true}
                  classes={{ disabled: classes.disabled }}
                />
              }
              label="Internet Equipment Fee is included"
              labelPlacement="end"
              classes={{ label: classes.label }}
              disabled
            />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card style={{ marginTop: 20 }}>
          <CardHeader title="Additional Options" />
          <CardContent>
            <AdditionalOptions classes={classes} record={record} />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card style={{ marginTop: 20 }}>
          <CardHeader title="Promotions Options" />
          <CardContent>
            <PromotionsOptions classes={classes} record={record} />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card style={{ marginTop: 20 }}>
          <CardHeader title="Shipping Information" />
          <CardContent>
            <ShippingInformation classes={classes} record={record} />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card style={{ marginTop: 20 }}>
          <CardHeader title="Terms and Conditions" />
          <CardContent>
            <FormControlLabel
              control={
                <Checkbox
                  checked={true}
                  classes={{ disabled: classes.disabled }}
                />
              }
              label="I agree to the terms and conditions"
              labelPlacement="end"
              classes={{ label: classes.label }}
              disabled
            />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card style={{ marginTop: 20 }}>
          <CardHeader title="Billing info" />
          <CardContent>
            <BillingInfo record={record} />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Activity record={record} classes={classes} />
      </Grid>
    </Fragment>
  )
}
